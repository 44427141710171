import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"
import getSlug from "speakingurl"

const MenuMainDesktop = () => {

  const data = useStaticQuery(
    graphql`
      query MenuMainDesktop {
        dataJson(slug: {eq: "menu-main"}) {
          fragment_0
          fragment_1
          fragment_2
          fragment_3
          fragment_4
          fragment_5
        }
      }
    `
  )

  const dataFragments = data.dataJson

  const contentMenu = [
    [ '', dataFragments.fragment_0 ],
    [ getSlug(dataFragments.fragment_1), dataFragments.fragment_1 ],
    [ getSlug(dataFragments.fragment_2), dataFragments.fragment_2 ],
    [ getSlug(dataFragments.fragment_3), dataFragments.fragment_3 ],
    [ getSlug(dataFragments.fragment_4), dataFragments.fragment_4 ],
    [ getSlug(dataFragments.fragment_5), dataFragments.fragment_5 ]
  ]

  return(
    <Flex
      as='div'
      className="menu"
      sx={{
        alignItems: `center`,
        justifyContent: `flex-end`,
        height: `100%`,
        "a[aria-current]": {
          color: 'secondary',
        },
        "menu-items": {
          justifyContent: `center`,
          m: 0,
        },
        li: {
          fontSize: 1,
          lineHeight: `snug`,
          listStyle: `none`,
          pr: 3,
        },
        "li:last-of-type": {
          pr: 0,
        },
        a: {
          color: `white`,
          borderBottom: `none`,
          ":hover": {
            color: `white`,
            borderBottom: theme => `1px solid ${theme.colors.gray[1]}`,
          },
        },
        'a[aria-current="page"]': {
          color: `tertiary`,
          borderBottom: theme => `1px solid ${theme.colors.tertiary}`,
        }
      }}
    >
      <Box
        as="nav"
        aria-label="Primary Navigation"
      >
        <Flex
          as="ul"
          className="menu-items"
        >
          {contentMenu.map((item, index) => (
            <Themed.li key={index}>
              <Link
                to={`/${item[0]}`}
                rel="bookmark"
              >
                {item[1]}
              </Link>
            </Themed.li>
          ))}
      </Flex>
      </Box>
    </Flex>
  )
}

export default MenuMainDesktop