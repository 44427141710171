import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Flex } from "theme-ui"
import getSlug from "speakingurl"

const MenuMain = () => {

  const data = useStaticQuery(
    graphql`
      query MenuMain {
        dataJson(slug: {eq: "menu-main"}) {
          fragment_0
          fragment_1
          fragment_2
          fragment_3
          fragment_4
          fragment_5
        }
      }
    `
  )

  const dataFragments = data.dataJson

  const contentMenu = [
    [ '', dataFragments.fragment_0 ],
    [ getSlug(dataFragments.fragment_1), dataFragments.fragment_1 ],
    [ getSlug(dataFragments.fragment_2), dataFragments.fragment_2 ],
    [ getSlug(dataFragments.fragment_3), dataFragments.fragment_3 ],
    [ getSlug(dataFragments.fragment_4), dataFragments.fragment_4 ],
    [ getSlug(dataFragments.fragment_5), dataFragments.fragment_5 ]
  ]

  return(
    <Box
      as='div'
      className="menu"
      sx={{
        backgroundColor: `secondary`,
        height: `calc(100vh - 64px)`,
        left: 0,
        position: `absolute`,
        pt: 5,
        textAlign: `right`,
        top: `64px`,
        width: `100vw`,
        zIndex: `1`,
        "a[aria-current]": {
          color: 'secondary',
        },
        ".menu-items": {
          flexDirection: `column`,
          m: 0,
        },
        li: {
          listStyle: `none`,
          pr: 3,
        },
        a: {
          color: `white`,
          fontFamily: `body`,
          fontSize: 4,
          borderBottom: `none`,
          ":hover": {
            color: `white`,
            borderBottom: theme => `1px solid ${theme.colors.gray[1]}`,
          },
        },
        "a[aria-current='page']": {
          color: `white`,
          borderBottom: theme => `1px solid ${theme.colors.gray[1]}`,
        }
      }}
    >
      <Box
        as="nav"
        aria-label="Primary Navigation"
      >
        <Flex
          as="ul"
          className="menu-items"
        >
          {contentMenu.map(item => (
            <li>
              <Link
                to={`/${item[0]}`}
                rel="bookmark"
              >
                {item[1]}
              </Link>
            </li>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default MenuMain